import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {FileSystem} from "@/helpers/file/FileSystem";

export const getConfig = async (fileName = "app-settings") => {

    // Create storage
    const storage = new AbStorage({
        load: async () => {

            // Try to load from file first
            try {
                // Load file
                console.log("Loading settings from file")
                let fData = await FileSystem.readFile(fileName+".json")
                if(!fData) fData = await FileSystem.readFile("settings.json")
                if(!fData) throw "No settings file found"
                return JSON.parse(fData);
            } catch (ex) {
                console.log("Error loading settings from file: ", ex)
            }

            // Load from local storage
            if(typeof localStorage === 'undefined') return {}

            try {
                let fData = localStorage.getItem(fileName, "{}")
                if(!fData) fData = localStorage.getItem("app_settings", "{}")
                return JSON.parse(fData)
            } catch (ex) {
                return {}
            }
        },
        save: (saveData) => {

            // Save only settings key
            saveData = {settings: saveData.settings || {}}

            // Try to save to file first
            try {
                // Load file
                console.log("Saving settings to file")
                FileSystem?.writeFile(fileName+".json", JSON.stringify(saveData))
            } catch (ex) {
                console.log("Error saving settings from file: ", ex)
            }

            // Save to local storage
            if(typeof localStorage === 'undefined') return false;
            localStorage.setItem(fileName, JSON.stringify(saveData))
        }
    });

    // Load storage
    await storage.loadStorage()

    // Return storage
    return storage
}
