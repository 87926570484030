import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import {Capacitor} from "@capacitor/core"

export class FileSystem {
    /**
     * Write file
     * @param path
     * @param data
     * @param encoding
     * @param directory
     */
    static async writeFile(path, data, encoding = Encoding.UTF8, directory = Directory.Data) {
        const opts = {
            path,
            data,
            directory,
            recursive: true
        }
        if(encoding) opts.encoding = encoding
        return await Filesystem.writeFile(opts)
    }

    /**
     * Get file size
     * @param path
     * @param directory
     */
    static async fileSize(path, directory = Directory.Data) {
        try {
            return (await Filesystem.stat({
                path,
                directory,
            }))?.size || null;
        } catch (ex) {
            return null;
        }
    }

    /**
     * Get file uri
     * @param path
     * @param directory
     */
    static async getUri(path, directory = Directory.Data) {
        try {
            return (await Filesystem.getUri({
                path,
                directory,
            }))?.uri || null;
        } catch (ex) {
            return null;
        }
    }

    /**
     * Read file
     * @param path
     * @param encoding
     * @param directory
     */
    static async readFile(path, encoding = Encoding.UTF8, directory = Directory.Data) {
        try {
            return (await Filesystem.readFile({
                path,
                directory,
                encoding
            }))?.data;
        } catch (ex) {
            return null;
        }
    }

    /**
     * Check if file exists
     * @param path
     * @param directory
     */
    static async exists(path, directory = Directory.Data) {
        try {
            return (await Filesystem.stat({
                path,
                directory,
            }))?.uri;
        } catch (ex) {
            return false;
        }
    }

    /**
     * Delete file
     * @param path
     * @param directory
     */
    static async deleteFile(path, directory = Directory.Data) {
        try {
            return await Filesystem.deleteFile({
                path,
                directory,
            });
        } catch (ex) {
            return null;
        }
    }

    /**
     * Copy file
     * @param from
     * @param to
     * @param directory
     * @param toDirectory
     * @return {Promise<*|null>}
     */
    static async copy(from, to, directory = Directory.Data, toDirectory = directory) {
        try {
            return await Filesystem.copy({
                from,
                to,
                directory,
                toDirectory: toDirectory
            });
        } catch (ex) {
            return null;
        }

    }

    /**
     * Convert local path to url
     * @param path
     */
    static convertFileSrc(path) {
        return Capacitor.convertFileSrc(path)
    }
}
