<template>
  <div ref="container" class="lottie" :style="{width: widthSize, height: heightSize}"></div>
</template>

<script>

import lottie from "lottie-web";

export default {
  name: "LottiePlayer",
  emits: ["onAnimationLoaded", "onComplete"],
  props: {
    animationData: {
      default: false
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },

    autoPlay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    widthSize: null,
    heightSize: null
  }),

  setup() {
    return {
      player: false,
    }
  },

  created() {
    this.widthSize = this.width ? `${this.width}px` : "100%";
    this.heightSize = this.height ? `${this.height}px` : "100%";
  },

  mounted() {

    const container = this.$refs.container
    const config = {
      container: container, // the DOM element that will contain the animation
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoPlay,
    }

    // Set animation data according to type
    if(typeof this.animationData === "string") {
      config.path = this.animationData
    } else {
      config.animationData = this.animationData
    }

    this.player = lottie.loadAnimation(config);

    // On loaded
    this.player.addEventListener('DOMLoaded', (data) => {
      this.$emit("onAnimationLoaded", data)
    });
    // On loaded
    this.player.onComplete = (data) => {
      this.$emit("onComplete", data)
    }
  },

  beforeUnmount() {
    this.player.destroy();
  },

  methods: {

    /**
     * Get animation duration
     * @return {*}
     */
    getDuration(inFrames = true) {
      return this.player.getDuration(inFrames);
    },

    /**
     * Play animation segment
     * @param segment
     * @param force
     */
    playSegments(segment, force = true) {
      this.player.playSegments(segment, force);
    },

    /**
     * Go to frame and play
     * @param frame
     * @param isFrame
     */
    goToAndPlay(frame, isFrame = true) {
      this.player.goToAndPlay(frame, isFrame);
    },

    /**
     * Pause animation
     */
    pause() {
      this.player.pause();
    },
  },

}

</script>
