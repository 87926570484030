import {A2UAudioPlayer} from "a2u-capacitor-audio-player-plugin";
import {PermissionCall} from "a2u-capacitor-permission-plugin";
import {AbIAPClient} from "a2u-capacitor-iap-plugin";
import {IAPManager} from "./helpers/iap/IAPManager";
import {CasCall} from "a2u-capacitor-cas-plugin";
import {CasAdapter} from "./helpers/ads/CasAdapter";
import {InAppReview} from "@capacitor-community/in-app-review";
import {Haptics} from "@capacitor/haptics";
import {A2UNativeBrowser} from "a2u-capacitor-native-browser-plugin";
import {KeepAwake} from "@capacitor-community/keep-awake";
import {SingularAnalytics} from "./helpers/Analytics/SingularAnalytics";
import {StatusBar} from "@capacitor/status-bar";
import {SafeArea} from "capacitor-plugin-safe-area";
import {Keyboard} from "@capacitor/keyboard";
import {ShopifyAdapter} from "./helpers/products/ShopifyAdapter";
import {AdvertisingId} from "@capacitor-community/advertising-id"

export const moduleConfig = {
  plugins: {A2UAudioPlayer, PermissionCall, AbIAPClient, IAPManager, CasCall, CasAdapter, RateApp: InAppReview, Haptics, A2UNativeBrowser, KeepAwake, MMPProvider: SingularAnalytics.getInstance({"sdkKey":"vaku_app_8426abbe","sdkSecret":"b2c7a536d032dc238740b6f4397bb64d"}), StatusBar, SafeArea, Keyboard, ShopifyAdapter, AdvertisingId},

  mixin: {
    async created() {
      
    },

    async beforeUnmount() {
      
    }
  }
}
