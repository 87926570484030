import {Capacitor} from "@capacitor/core"
import {CapacitorSQLite, SQLiteConnection} from '@capacitor-community/sqlite';
import {FileSystem} from "@/helpers/file/FileSystem";

export class DbAdapter {

    /**
     * Constructor
     * @param config
     */
    constructor(config) {
        this.dbConfig = config
    }


    /**
     * Init db
     */
    async init() {

        // Create new sqlite connection
        this.sqlite = new SQLiteConnection(CapacitorSQLite);

        // Db name
        const dbName = `db-${this.dbConfig.name}-sqlite`;

        // Close all old connections, in case of manual webview restart
        try {
            // Preventive solution
            await this.sqlite.closeConnection(dbName);
        } catch (e) {
            // Log error, but continue
            console.error('Failed to close connections', e);
        }

        try {
            let dbPath = `../databases/${dbName}SQLite.db`;

            // Check if platform is ios and set db path
            if (Capacitor.getPlatform() === 'ios') {
                // Documents/some-dbSQLite.db
                dbPath = `${dbName}SQLite.db`;
            }

            // check if db file not exists and old db exists
            if (!(await FileSystem.exists(dbPath)) &&
                (await FileSystem.exists("db-" + this.dbConfig.name))
            ) {
                // Copy old to new db, unfortunately they have different paths
                await FileSystem.copy("db-" + this.dbConfig.name, dbPath);
                console.log("Old DB copied to new DB")
            }
        } catch (e) {
            // Log error, but continue
            console.error('Failed to copy old db', e);
        }

        try {
            // Create new database
            this.db = await this.sqlite.createConnection(dbName, false, 'no-encryption', 1);
        } catch (e) {
            // Log error
            console.error('Failed to create database', e);

            // Return null means we will use sql.js, at least app will not crash
            return null;
        }

        try {
            // Open the database connection
            await this.db.open();
        } catch (e) {
            // Log error
            console.error('Failed to open database', e);

            // Return null means we will use sql.js, at least app will not crash
            return null;
        }

        // Everything is ok, return initialized adapter
        return this;
    }


    /**
     * Execute query
     * @param query
     * @param params
     * @return {Promise<*>}
     */
    async query(query, params) {
        // Execute query
        return (await this.db.query(query, params))?.values
    }

    /**
     * Close db
     */
    dispose() {
        this.db?.close?.();
    }
}
