import axios from 'axios';
import { Directory } from '@capacitor/filesystem';
import {FileSystem} from '../file/FileSystem.js';

/**
 * Class representing a CDN Loader.
 */
export class CdnLoader {
  /**
   * Create a CDN Loader.
   * @param {Object} options - The options for the CDN Loader.
   * @param {Object} options.manager - The manager for the CDN Loader.
   * @param {string} options.src - The source for the CDN Loader.
   * @param {number} options.timeout - The timeout for the CDN Loader.
   * @param {Function} options.progressCallback - The progress callback for the CDN Loader.
   */
  constructor({manager, src, timeout, progressCallback}) {
    this.manager = manager;
    this.src = src;
    this.timeout = timeout;
    this.progressCallback = progressCallback;

    this.cdnPath = `${this.manager.cdnServer}/${this.src}`;
    this.localPath = `remote/${this.src}`;
  }

  /**
   * Download the file from the CDN.
   * @async
   */
  async download() {
    const {data} = await axios({
      url: `${this.cdnPath}`,
      method: 'GET',
      responseType: 'blob',
      timeout: (this.timeout || 30) * 1000,
      onDownloadProgress: ({loaded, total}) => {
        if (typeof this.progressCallback === 'function') {
          this.progressCallback(Math.min(100, Math.ceil((loaded * 100) / total)));
        }
      },
    });

    if (this.manager.platform === 'web') {
      return;
    }

    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1];

        await FileSystem.writeFile(this.localPath, base64data, false, Directory.Cache);

        resolve();
      };
      reader.readAsDataURL(data);
    });
  }

  /**
   * Get the size of the remote file.
   * @async
   * @returns {Promise<number>} The size of the remote file.
   */
  async getRemoteFileSize() {
    const response = await axios.head(this.cdnPath);

    return parseInt(response.headers['content-length'], 10);
  }

  /**
   * Update the local file if the remote file has changed.
   * @async
   */
  async updateFile() {
    const remoteSize = await this.getRemoteFileSize();
    const localSize = await FileSystem.fileSize(this.localPath, Directory.Cache);

    if (remoteSize === localSize) {
      return;
    }

    await this.download();
  }

  /**
   * Load the file from the CDN or the local file system.
   * @async
   * @returns {Promise<string>} The URI of the file.
   */
  async load() {
    if (this.manager.runMode === 'debug') {
      if (typeof this.progressCallback === 'function') {
        this.progressCallback(100);
      }

      return `${process.env.VUE_APP_STORAGE_PATH.replace(/\/$/, '')}/${this.src}`;
    }

    if (this.manager.platform === 'web') {
      if (typeof this.progressCallback === 'function') {
        this.progressCallback(100);
      }

      return this.cdnPath;
    }

    if (await FileSystem.exists(this.localPath, Directory.Cache)) {
      this.updateFile();

      return FileSystem.convertFileSrc(await FileSystem.getUri(this.localPath, Directory.Cache));
    }

    await this.download();

    return FileSystem.convertFileSrc(await FileSystem.getUri(this.localPath, Directory.Cache));
  }
}
