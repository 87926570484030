<template>
  <div class="game-line-drawer" ref="container" :class="{'hint': hintShown}">
    <canvas id="drawHint" :width="width" :height="height"/>
    <canvas id="drawCanvas" :width="width" :height="height"
            @mousedown.stop.prevent="beginDrawing"
            @touchstart.stop.prevent="beginDrawing"

            @mousemove.stop.prevent="keepDrawing"
            @touchmove.stop.prevent="keepDrawing"

            @mouseup.stop.prevent="stopDrawing"
            @touchend.stop.prevent="stopDrawing"
    />
  </div>
</template>

<script>

import greenlet from 'greenlet'

export default {
  name: "LineDrawer",
  emits: ['completed', 'attempt', 'wrong'],
  props: {
    width: {},
    height: {},
    mask: {},
    levelAnimSize: {},
    boundsThreshold: {
      type: Number,
      default: 60
    },
    drawingThreshold: {
      type: Number,
      default: 60
    },
  },
  data: () => ({
    x: 0,
    y: 0,
    isDrawing: false,
    hintShown: false,
    canvas: null,
    maskData: [],
    maskImage: false,
    maskCanvas: false
  }),

  mounted() {
    var c = document.getElementById("drawCanvas");
    this.canvas = c.getContext('2d', { willReadFrequently: true });

    // Get hint canvas
    var c2 = document.getElementById("drawHint");
    this.hintCanvas = c2.getContext('2d', { willReadFrequently: true });

    // Load mask
    this.loadMask()
  },

  /**
   * Before unmount
   */
  beforeUnmount() {
    this.cleanUp()
  },

  methods: {

    /**
     * Show hint
     */
    showHint() {

      // Hint is shown
      this.hintShown = true

      // Show for time
      setTimeout(() => {

        // Clear canvas
        this.hintCanvas.clearRect(0, 0, this.width, this.height);

        // Hint is shown
        this.hintShown = false

      }, 6000)

      // Draw hint
      this.hintCanvas.drawImage(this.maskImage, 0, 0, this.maskImage.width, this.maskImage.height, (this.width - this.levelAnimSize) / 2, (this.height - this.levelAnimSize) / 2, this.levelAnimSize, this.levelAnimSize);
    },

    /**
     * Clean up
     */
    cleanUp() {

      // Remove image canvas
      this.maskCanvas.remove();
    },

    /**
     * Load mask
     */
    loadMask() {

      // Create image
      this.maskImage = new Image();
      this.maskImage.crossOrigin = "Anonymous";

      // Set source
      this.maskImage.src = this.mask;

      // Wait until image is loaded
      this.maskImage.onload = () => {

        // Create canvas
        this.maskCanvas = document.createElement('canvas');
        this.maskCanvas.width = this.width;
        this.maskCanvas.height = this.height;

        // Draw image on canvas
        this.maskCanvas.getContext('2d', { willReadFrequently: true }).drawImage(this.maskImage, 0, 0, this.maskImage.width, this.maskImage.height, (this.width - this.levelAnimSize) / 2, (this.height - this.levelAnimSize) / 2, this.levelAnimSize, this.levelAnimSize);

        // Store mask data
        const imageData8 = this.maskCanvas.getContext('2d', { willReadFrequently: true }).getImageData(0, 0, this.width, this.height)?.data;
        this.maskData = new Int32Array(imageData8.buffer.slice(imageData8.byteOffset), 0, imageData8.length/4);
      }
    },

    /**
     * Check if figure is completed
     */
    async checkFigureCompleted() {

      // Get current canvas image data
      const imageData8 = this.canvas.getImageData(0, 0, this.width, this.height).data;
      const imageData = new Int32Array(imageData8.buffer.slice(imageData8.byteOffset), 0, imageData8.length/4);

      let calculate = greenlet( async (width, height, maskData, imageData8, imageData) => {

        // Calc boundaries
        const drawnB = {minX: 0, maxX: 0, minY: 0, maxY: 0}
        const maskB = {minX: 0, maxX: 0, minY: 0, maxY: 0}

        // Total intersections
        let intersections = 0, drawnCount = 0;

        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const index = x + y * width;
            const drawnPixel = imageData[index] !== 0;
            const maskPixel = maskData[index] !== 0;

            // Check if drawn pixel and update boundaries
            if (drawnPixel) {
              if (drawnB.minX === 0 || drawnB.minX > x) drawnB.minX = x;
              if (drawnB.maxX === 0 || drawnB.maxX < x) drawnB.maxX = x;
              if (drawnB.minY === 0 || drawnB.minY > y) drawnB.minY = y;
              if (drawnB.maxY === 0 || drawnB.maxY < y) drawnB.maxY = y;
              drawnCount++;
            }

            // Check if mask pixel and update boundaries
            if (maskPixel) {
              if (maskB.minX === 0 || maskB.minX > x) maskB.minX = x;
              if (maskB.maxX === 0 || maskB.maxX < x) maskB.maxX = x;
              if (maskB.minY === 0 || maskB.minY > y) maskB.minY = y;
              if (maskB.maxY === 0 || maskB.maxY < y) maskB.maxY = y;
            }

            // If pixels match
            if (drawnPixel && maskPixel) intersections++;
          }
        }

        // Return result
        return {intersections, drawnCount, drawnB, maskB}
      })

      // Calc result
      const {intersections, drawnCount, drawnB, maskB} = await calculate(this.width, this.height, this.maskData, imageData8, imageData)

      // Make decision
      const drawnCompleted = 100 * intersections / drawnCount;

      // Get intersection square fraction of mask square
      const interSquare = 100 * Math.sqrt(this.getIntersectionSquare(drawnB, maskB)) / Math.sqrt(this.getIntersectionSquare(maskB, maskB));

      console.log(drawnB, maskB, `interSquare: ${interSquare}(${this.boundsThreshold}), drawnCompleted: ${drawnCompleted}(${this.drawingThreshold})`)

      // Check for accuracy
      if (interSquare > this.boundsThreshold && drawnCompleted > this.drawingThreshold) return true;
    },

    /**
     * Get intersection square
     * @param bounds1
     * @param bounds2
     * @return {number}
     */
    getIntersectionSquare(bounds1, bounds2) {
      const minX = Math.max(bounds1.minX, bounds2.minX);
      const maxX = Math.min(bounds1.maxX, bounds2.maxX);
      const minY = Math.max(bounds1.minY, bounds2.minY);
      const maxY = Math.min(bounds1.maxY, bounds2.maxY);

      const width = Math.max(0, maxX - minX);
      const height = Math.max(0, maxY - minY);

      return width * height;
    },


    /**
     * check mask
     * @param x
     * @param y
     */
    checkMask(x, y) {
      return this.maskData[(x + y * this.width) * 4] !== 0
    },


    /**
     * Draw link
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     */
    drawLine(x1, y1, x2, y2) {
      let ctx = this.canvas;
      ctx.beginPath();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 5;
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    },

    /**
     * Get event offset
     * @return {{offsetX: number, offsetY: number}}
     * @param ev
     */
    getEventOffset(ev) {

      // Get event according to touch or mouse
      const e = ev.touches ? ev.touches?.[0] : ev;

      // No touches
      if (!e) return;

      // Get offset
      const rect = this.$refs.container.getBoundingClientRect();

      // Return offset
      return {
        offsetX: e.clientX - rect.left,
        offsetY: e.clientY - rect.top
      }
    },

    /**
     * Begin drawing
     * @param ev
     */
    beginDrawing(ev) {
      const e = this.getEventOffset(ev)
      this.x = e.offsetX;
      this.y = e.offsetY;
      this.isDrawing = true;
    },
    keepDrawing(ev) {
      const e = this.getEventOffset(ev)
      this.$refs.container.style.cursor = this.checkMask(e.offsetX, e.offsetY) ? 'pointer' : 'default'

      if (this.isDrawing === true) {
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = e.offsetX;
        this.y = e.offsetY;
      }
    },
    async stopDrawing() {
      if (this.isDrawing === true) {
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;

        // Attempt
        this.$emit('attempt');

        // Check if figure is completed
        if (await this.checkFigureCompleted()) {
          this.$emit('completed')
        } else {
          this.$emit('wrong')
        }

        // Clear canvas
        this.clearCanvas()
      }
    },

    /**
     * Clear canvas
     */
    clearCanvas() {
      this.canvas.clearRect(0, 0, this.width, this.height);
    }
  }
}

</script>

<style lang="scss">

.game-line-drawer {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  //background: red;
  //opacity: 0.;
  z-index: 100;

  #drawHint {
    position: absolute;
    z-index: 1;
    filter: blur(5px) invert(21%) sepia(100%) saturate(7414%);
  }

  #drawCanvas {
    position: absolute;
    z-index: 2;
  }

  @keyframes hintFadeOut {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 0.8;
    }
  }

  &.hint {
    #drawHint {
      animation: hintFadeOut 0.7s ease-in-out infinite alternate;
    }
  }
}

</style>

