<template>
  <div class="game-container" :key="levelData?.id +'-' + upKey" ref="canvas">
    <div class="game-zone" :class="mode">

      <div v-if="!isLoaded" class="loading">

      </div>
      <div class="game-canvas">
        <template v-if="isReady">
          <line-drawer v-if="maskSource && mode==='idle'" ref="drawer" :width="canvasWidth" :height="canvasHeight" :level-anim-size="levelAnimSize" :mask="maskSource"
                       @completed="completeLevel" @attempt="didAttempt" @wrong="shakeImage"/>

        </template>
        <div class="lottie-animation-level" :class="{shake: isShaken}">
          <template v-if="isAnimatedLevel">
            <lottie-player
                v-if="animationSource"
                ref="player"
                :animationData="animationSource"
                :loop="false"
                :autoPlay="false"
                :width="levelAnimSize"
                :height="levelAnimSize"
                @onAnimationLoaded="onLoaded"
                @onComplete="loopCompleted"
            />
          </template>
          <div v-else class="img-cnt">
            <img :width="levelAnimSize" :height="levelAnimSize" class="img-before" :class="`${mode}`" :src="beforeImageSource" @load="onLoaded"/>
            <img :width="levelAnimSize" :height="levelAnimSize" class="img-after" :class="`${mode}`"  :src="afterImageSource" @load="onLoaded"/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import LineDrawer from "./LineDrawer.vue";
import LottiePlayer from "@/components/Gameplay/LottiePlayer.vue";

export default {
  name: "DopGame",
  emits: ["puzzle-solved", "level-completed", "attempt-count", "loading-error", "level-loaded"],
  inject: ["renderer"],
  props: {
    levelData: {
      default: false
    }
  },
  components: {LottiePlayer, LineDrawer},
  data: () => ({
    upKey: 0,
    attempts: 0,
    isShaken: false,
    player: false,
    animationInfo: false,
    mode: "loading",
    isReady: false,
    isLoaded: false,
    loadTimeout: false,
    canvasHeight: false,
    canvasWidth: 0,
    levelAnimSize: 0,
  }),
  mounted() {

    console.log("DopGame", this.levelData)

    // Get canvas size
    this.canvasWidth = this.$refs.canvas.offsetWidth;
    this.canvasHeight = this.$refs.canvas.offsetHeight;

    // Set square size
    this.levelAnimSize = this.canvasWidth > 400 ? 400 : this.canvasWidth;

    // Set loading mode on level change
    this.$watch("levelData", () => {
      this.attempts = 0;
      this.mode = "loading"
      this.isLoaded = false
      this.startTimeout()
    }, {deep: true})

    // Page is ready
    this.isReady = true
  },

  computed: {

    /**
     * Is animated level
     * @return {boolean}
     */
    isAnimatedLevel() {
      return !!this.levelData?.level
    },

    /**
     * Mask source
     */
    maskSource() {
      return this.levelData ? this.renderer.getA2U().assetPath(this.levelData?.mask) : false;
    },

    /**
     * Animation source
     */
    animationSource() {
      return this.levelData ? this.renderer.getA2U().assetPath(this.levelData?.level) : false;
    },

    /**
     * Before image
     */
    beforeImageSource() {
      return this.levelData ? this.renderer.getA2U().assetPath(this.levelData?.img_before) : false;
    },

    /**
     * After image
     */
    afterImageSource() {
      return this.levelData ? this.renderer.getA2U().assetPath(this.levelData?.img_after) : false;
    }

  },

  methods: {

    /**
     * Shake image
     */
    shakeImage() {
      this.isShaken = true;
      setTimeout(() => {
        this.isShaken = false;
      }, 500)
    },

    /**
     * Start loading timeout
     */
    startTimeout() {
      this.loadTimeout = setTimeout(() => {
        this.$emit("loading-error")
      }, 20*1000)
    },

    /**
     * Attempt counter
     */
    didAttempt() {
      this.attempts++;
      this.$emit("attempt-count", this.attempts)
    },

    /**
     * Call action
     * @param action
     */
    call(action) {
      switch (action) {
          // Show clue
        case "show-clue":
          this.$refs.drawer.showHint()
          break
          // Reload level
        case "reload-level":
          this.upKey++;
          this.startTimeout();
          break
      }
    },

    /**
     * On loop completed
     */
    loopCompleted() {

      // If loop ended in success mode - stop animation
      if (this.mode === "success") {

        // Stop animation
        this.player?.pause();

        // Call level done
        this.levelCompleted()

        // Animation completed
        this.mode = "completed"

      } else {
        // Start idle animation
        this.idleSegment()
      }
    },

    /**
     * Level done
     */
    levelCompleted() {
      // Level done
      this.$emit("level-completed")

    },

    /**
     * On animation loaded
     */
    onLoaded() {

      // Clear timeout
      clearTimeout(this.loadTimeout)

      // Store player
      this.player = this.$refs.player;

      // Loaded
      this.isLoaded = true

      // Set idle mode
      this.mode = "idle"

      // Animation
      if(this.player) {

        // Get duration
        const duration = this.player.getDuration();

        // Set animation settings
        this.animationInfo = {
          duration,
          idleSegment: [0, 30],
          successSegment: [30, duration],
        }

        // Start idle animation
        this.idleSegment()
      }

      // Emit loaded
      this.$emit("level-loaded")
    },

    /**
     * Start idle segment
     */
    idleSegment() {

      // Set current mode
      this.mode = "idle"

      // Start idle animation
      this.player.playSegments(this.animationInfo.idleSegment, true);
    },

    /**
     * Play till the end
     */
    completeLevel() {

      // Emit puzzle solved
      this.$emit("puzzle-solved")

      // Set success mode
      this.mode = "success"

      // Start success segment
      if(this.isAnimatedLevel) {
        this.player?.playSegments(this.animationInfo.successSegment, true);
      } else {
        setTimeout(() => {
          this.loopCompleted()
        }, 3000)
      }
    }
  }
}

</script>

<style lang="scss">

// Fade in animation
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Fade out animation
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

// Shake animation
@keyframes shake-animation {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.game-container {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;

  .lottie-animation-salute {
    position: absolute;
    z-index: 1;
    scale: 2;
    display: none;
  }

  .lottie-animation-level {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .completed .lottie-animation-salute {
    display: block;
  }

  .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .game-zone {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
  }

  .loading .game-canvas {
    visibility: hidden;
  }

  .game-canvas {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .game-actions {
    height: 50px;
  }

  .shake {
    animation: shake-animation 0.5s infinite;
  }

  .img-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .img-before {
    position: absolute;
    display: block;
    &.idle {
      // Show animation
      animation: fadeOut 0.5s reverse;
    }
  }

  .img-after {
    position: absolute;
    display: none;
    &.success, &.completed, {
      display: block;
      // Show animation
      animation: fadeOut 0.5s reverse;
    }
  }

}

</style>

<script setup>
</script>
