import {ATUFunctionPackage} from "a2u-renderer-common/src/utils/processor/ATUFunctionPackage";

export class Statistics extends ATUFunctionPackage {

    /**
     * Constructor
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Update sorting in db
     * @return {*}
     */
    async updateSorting() {

        // Load sorting from server
        try {
            // Get sorting
            const sorting = JSON.parse(await this.callFunction("Statistics", "getSorting"));

            // Update local table game_levels field sort_id with values from sorting
            const db = this.getDb("db_dop");

            // Get all levels
            const levels = await db.getModel("game_levels").query().get();

            // Update sort_id
            for(const level of levels) {

                // Skip first 3 levels
                if(level.id < 4) continue;

                // Find sort
                const sort = sorting.find(item => parseInt(item.level) === level.id);
                if(sort) {
                    level.sort_id = parseFloat(sort.avatts) || 5;
                    await level.save();
                    //console.log(`Update level ${level.id} sort_id to ${level.sort_id}`)
                }
            }

            // Get all levels
            //console.log("levles", await db.getModel("game_levels").query().get());


        } catch (e) {
            console.error("Error getting sorting", e)
        }
    }
}
