import {CasCall} from 'a2u-capacitor-cas-plugin';

export class CasAdapter {

    // Test ids
    static testIds = {
        appopen: 'ca-app-pub-3940256099942544/3419835294',
        interstitial: 'ca-app-pub-3940256099942544/1033173712',
        banner: 'ca-app-pub-3940256099942544/6300978111',
        rewarded: 'ca-app-pub-3940256099942544/5224354917',
    };

    /**
     * Ad config
     * @param manager
     * @param config
     */
    constructor(manager, config) {
        this.config = config;
        this.manager = manager;

        // Setup admobId according to platform
        for (const placement of this.config?.placements || []) {

            // Patch ads for different platforms
            switch (this.manager.a2u.getPlatform()) {

                // Ios
                case 'ios':
                    if (placement?.iosAdmobId) placement.admobId = placement.iosAdmobId
                    break;

                // All the others
                default:
                    break
            }
        }
    }

    /**
     * Init Adapter ads
     * @return {Promise<void>}
     */
    async init({debug}) {

        const status = this.manager.a2u.getPlatform() === 'web' ? true : await CasCall.init({debug})
        this.manager.log("CAS initialization: status:", JSON.stringify(status))

        // Add revenue listener
        CasCall.addListener(
            "ad_revenue",
            (result) => {

                // Find placement name by id
                const placementName = (this.config.placements || []).find((info) => info.admobId === result.adUnitId)?.name

                // Calc revenue
                let revenue = result?.revenue || 0

                // Limit revenue
                if(revenue > 0.15) revenue = 0.15

                // Send revenue event
                if(result?.revenue) this.manager.a2u.analytics.logAdRevenue("ad_revenue", "cas", result.type, placementName || result.adUnitId || "Unknown", revenue)
            }
        )

        // Return this
        return this;
    }

    /**
     * Request ad estimation
     * @param name
     * @return {Promise<void>}
     */
    async requestPlacement(name) {

        // Log
        this.manager.log(`Requesting placement ${name}`)

        // Check if placement supported by this provider
        const placement = this.getPlacementInfo(name)

        // Not supported
        if (!placement) {
            this.manager.log(`No placement info in request for placement: ${name}`)
            return false
        }

        // Return estimation and load function
        return {
            cpm: 1,
            load: async () => {
                try {
                    return await this.loadPlacement(name)
                } catch (e) {
                    return null;
                }
            }
        }
    }

    /**
     * Get placement info
     * @param name
     */
    getPlacementInfo(name) {

        // Get placement info
        const info = this.config.placements.find(p => p.name === name)
        if (!info) return null

        // Patch ids for testing
        if (this.manager.isTesting) {
            info.admobId = CasAdapter.testIds[info.type]
        }

        // Return info
        return info
    }

    async load(type, plId) {

        try {
            // Wait for rewarded ad to load
            const res = await CasCall.loadPlacement(
                {
                    type: type,
                    adUnitId: plId
                }
            )

            // Return show function
            return {
                hide: async () => {
                    return CasCall.hideBanner()
                },
                show: async () => {
                    // Show rewarded ad
                    return new Promise((resolve, reject) => {

                        // Show ad in web platform
                        if (this.manager.a2u.getPlatform() === 'web') return resolve();

                        // Show ad
                        CasCall.show(
                            {
                                type: type,
                                adUnitId: plId
                            }
                        ).then(res => {
                            this.manager.log(`Ad ${name} shown successfully:`, res)
                            resolve(res)
                        }).catch(err => {
                            this.manager.log(`Ad ${name} show error:`, res)
                            reject(err)
                        })
                    })
                }
            }
        } catch (ex) {
            console.error("Native load error:", ex, ex?.data?.errorCode)
            throw ex?.data?.errorCode
        }
    }

    /**
     * Load ad
     * @param name
     */
    async loadPlacement(name) {

        // Get placement info
        const placement = this.getPlacementInfo(name)
        if (!placement) {
            this.manager.log(`Placement ${name} not found`)
            return
        }

        // Log
        this.manager.log(`Loading placement ${name}`)

        // Loading timeout
        let loadTimeout = null

        // Wait timeout seconds
        const result = await new Promise((resolve) => {

            // Loading timeout
            loadTimeout = setTimeout(() => {
                this.manager.log(`Placement ${name} type ${placement.type} loading timeout reached`)
                resolve(null)
            }, this.manager.defaultTimeout * 1000)

            // Load ad according to type
            switch (placement.type) {

                case "rewarded":
                case "interstitial":
                case "banner":

                    // Get placements list
                    var placementIds = placement.admobId.split(',')?.map(p => p.trim());

                    // Load level
                    var loadLevel = () => {
                        // Get and remove first id from placementIds
                        var id = placementIds.shift();

                        // Exit if no more ids
                        if (!id) return resolve({
                            error: "no-ads"
                        });

                        // Error
                        this.manager.log(`Loading placement ${name} type ${placement.type} id:`, id)

                        // Trying to load
                        this.load(placement.type, id).then((res) => {
                            // Loaded
                            resolve(res)
                        }).catch(err => {
                            // Error
                            this.manager.log(`Placement ${name} type ${placement.type} loading error:`, err)

                            // No ads on level
                            if (err === 1020) {
                                // Load next level
                                return loadLevel()
                            }

                            // Reject
                            resolve(null)
                        })
                    }

                    // Load first level
                    loadLevel()
                    break;

                default:
                    this.manager.log(`Placement ${name} type ${placement.type} not supported`)
                    resolve(null)
                    break;
            }
        })

        // Clear load timeout
        clearTimeout(loadTimeout)

        // Return ad
        return result
    }

    /**
     * Check if user has consent
     * @return {Promise<void>}
     */
    async checkAdConsent() {
        // Return consent status
        return (await CasCall.hasConsent())?.result;
    }

    /**
     * Show consent dialog
     * @return {boolean|*|boolean}
     */
    async showConsentDialog() {

        // Check consent first
        if (await this.checkAdConsent()) return true;

        // Dialog shown
        return (await CasCall.showGDPRDialog())?.result;
    }

    /**
     * Check privacy options status
     * @return {Promise<boolean>}
     */
    async checkPrivacyOptions() {
        // Return privacy options status
        return true;
    }

    /**
     * Show privacy options dialog
     * @return {boolean}
     */
    async showPrivacyOptions() {
        // Return privacy options status
        return true;
    }
}
