import { Capacitor } from '@capacitor/core';
import {A2UErrorHandler} from 'a2u-capacitor-error-handler-plugin';
import {CrashlyticsErrorHandler} from './error-handlers/CrashlyticsErrorHandler';

export default {
  /**
   * Install component
   */
  async install() {
    // Check if the platform is native
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    try {
      // Init crashlytics error handler
      const errorHandler = new CrashlyticsErrorHandler();

      // Register error handler listener
      A2UErrorHandler.addListener("errorHandler", errorHandler.processError);
    } catch (e) {
      console.error('Error installing A2UErrorHandler plugin: ', e);
    }
  }
}
