import { Capacitor } from '@capacitor/core';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

export default {
  /**
   * Install component
   */
  async install() {
    // Check if the platform is native
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    try {
      // Enables the FirebaseCrashlytics provider
      await FirebaseCrashlytics?.setEnabled({
        enabled: true,
      });
    } catch (e) {
      console.error(e);
    }
  }
}
