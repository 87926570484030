import {A2uErrorHandler} from './A2uErrorHandler';
import {ConsoleLogErrorHandler} from './ConsoleLogErrorHandler';
import {CrashlyticsErrorHandler} from './CrashlyticsErrorHandler';

// Register error handlers
export const errorHandlers = {
  a2u: A2uErrorHandler,
  console: ConsoleLogErrorHandler,
  crashlytics: CrashlyticsErrorHandler,
}
