// Import the functions you need from the SDKs you need
import {A2USingular} from "a2u-capacitor-singular-plugin";

export class SingularAnalytics {

    // Singleton instance
    static instance = undefined;

    /**
     * Init analytics
     */
    init(config) {

        // Init status
        this.inited = false

        try {
            // Init singular
            A2USingular.init(config).then(res => {
                console.log('Init singular', JSON.stringify(res))
                this.inited = true
            }).catch(e => {
                console.error('Init singular error:', e)
                this.inited = false
            })
        } catch (e) {
            console.error('Init singular error:', e)
            this.inited = false
        }

        // Return this
        return this
    }


    /**
     * Constructor
     */
    static getInstance(config) {

        // Create new instance
        if(SingularAnalytics.instance === undefined) {
            SingularAnalytics.instance = config ? new SingularAnalytics().init(config) : false
        }

        // Return instance
        return SingularAnalytics.instance;
    }

    /**
     * Set user property
     * @param key
     * @param value
     * @return {Promise<void>}
     */

    setUserProperty(key, value) {
        console.log('setUserProperty', key, value, ' - not implemented')
    }

    /**
     * Log ad revenue
     * @param data
     */
    async logAdRevenue(data) {
        if(!this.inited) return console.log('Singular not inited, revenue not sent');
        const result = await A2USingular.logAdRevenue(data)
        console.log("Singular send revenue:", JSON.stringify(data), JSON.stringify(result))
    }


    /**
     * Log event
     * @param name
     * @param Object
     */
    logEvent() {
        return false;
    }
}
