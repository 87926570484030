export class ShopifyAdapter {
  constructor(a2u) {
    this.a2u = a2u;
  }

  /**
   * Initialize the Shopify adapter
   * @param {Object} config - The configuration for the Shopify adapter.
   */
  async init(config) {
    // Define the main URL for the application
    const mainUrl = process.env.VUE_APP_A2U_URL;

    try {
      // Check if Shopify is configured in the source
      if (!config) {
        return;
      }

      // Extract the store name, database ID, and table ID from the Shopify configuration
      const { storeName, dbId, tableId } = config;

      // Validate the Shopify configuration
      if (!storeName || !dbId || !tableId) {
        console.error('Invalid Shopify configuration');

        return;
      }

      // Retrieve the database instance for the given database ID
      const db = this.a2u.dbs[dbId];

      // Check if the database is defined
      if (!db) {
        console.error(`Database is not defined for Shopify: ${storeName}`);
        return;
      }

      // Retrieve the field names of the table in the database
      const tableFields = (db?.config?.tables[tableId]?.fields || []).map((field) => field?.name);

      // Check if the table is defined
      if(!tableFields) {
        console.error(`Table is not defined for Shopify: ${storeName}`);
        return;
      }

      // Define the possible fields for a Shopify product
      const possibleFields = [
        'id',
        'title',
        'price',
        'url',
        'main_image',
        'images',
        'videos',
        'description',
        'description_html',
      ];

      // Check which of the possible fields exist in the table
      const existsFields = tableFields.filter((field) => possibleFields.includes(field));

      // Check if the table structure is compatible with the Shopify data
      if (!existsFields.length) {
        console.error(`Table structure is incompatible for Shopify: ${storeName}`);

        return;
      }

      // Retrieve the model for the table
      const productModel = db?.models[tableId];

      // Check if the model is defined
      if (!productModel) {
        return;
      }

      // Check the version of the Shopify store
      const { data: { hash, productsJson } } = await this.a2u.context.app.client.axios.get(`${mainUrl}/shopify/checkVersion/${storeName}`);

      // Get the current version from the configuration
      const currentVersion = this.a2u.context.app.client.config.get('settings.shopifyJsonVersion', undefined);

      // If the version has not changed, return
      if (hash && hash === currentVersion) {
        return;
      }

      // If the application is in release mode, retrieve the products from the Shopify API
      const { data: products } = await this.a2u.context.app.client.axios.get(`${productsJson}`);

      // Check if there are any products
      if (!products.length) {
        console.error(`No products found for Shopify: ${storeName}`);
        return;
      }

      // Delete all existing records in the table
      await db.queryRaw(tableId, `DELETE FROM ${productModel.entity} WHERE 1`, []);

      // Store each product in the table
      for (const product of products) {
        await productModel.save(
          existsFields.reduce((res, field) => {
            if (field === 'id') {
              return res;
            }

            res[field] = product[field] || null;

            return res;
          }, {}),
        );
      }

      // Store the new version hash
      if (hash) {
        this.a2u.context.app.client.config.set('settings.shopifyJsonVersion', hash);
      }
    } catch (e) {
      console.error('Error initializing Shopify', e);
    }
  }
}
