<template>
  <atu-renderer :key="updateKey" :db-config="db" :module-id="moduleId" :on-init="initApp" :platform="platform" :on-destroy="destroyApp" :run-mode="runMode" v-if="source" :db-server-url="dbServerUrl" :startEvent="startEvent" :source="source" :functions="methodList" :storage="storage" :native-components="componentList" :debug="10" :plugins="plugins" :language="appLocale" />
</template>

<script>

import {componentList} from "./componentList";
import db from "./db";
import {methodList} from "@/methodList";
import {Capacitor} from "@capacitor/core"
import {SchemaKeeper} from "app-to-up-vue-renderer/src/renderer/utils/schemaKeeper";
import { App } from '@capacitor/app';
import {FileSystem} from "@/helpers/file/FileSystem";
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Device } from '@capacitor/device';
import {DbAdapter} from "./helpers/db/DbAdapter";
import {CdnManager} from './helpers/cdn/CdnManager';
import {IndexedDbFilesManager} from './helpers/idb/IndexedDbFilesManager';
import {moduleConfig} from "./module-config";

export default {
  name: 'TestApp',

  mixins: [moduleConfig.mixin],

  computed: {
    methodList() {
      return methodList
    },
    db() {
      return db
    },
    componentList() {
      return componentList
    }
  },
  data: () => ({
    source: false,
    moduleId: false,
    storage: false,
    startEvent: "start",
    runMode: "debug",
    platform: "web",
    updateKey: 0,
    dbServerUrl: "",
    appLocale: null,
    plugins: {
      DbAdapter,
      FileSystem,
      AnalyticsProvider: FirebaseAnalytics,
      CrashlyticsProvider: FirebaseCrashlytics,
      App,
      CdnManager,
      IndexedDbFilesManager,
      ...moduleConfig.plugins
    }
  }),
  async created() {

    // Get segments
    //const [, mId, mode] = window.location.pathname.split('/')

    // Get platform
    this.platform = Capacitor.getPlatform();

    // Store run mode
    this.runMode = process.env.VUE_APP_RUN_MODE

    // Check for stage mode and hash
    if(this.runMode !== "release" && window.location.hash) this.runMode = window.location.hash.slice(1)

    // Set db url
    this.dbServerUrl = process.env.VUE_APP_DB_URL

    // Get appId from hash
    this.moduleId = parseInt(process.env.VUE_APP_MODULE_ID);

    // Log start event
    console.log("Start event: ", this.startEvent)

    // Set file system to app
    this.app.fileSystem = FileSystem

    // Create schema keeper
    const schemaKeeper = new SchemaKeeper(this.app, this.moduleId, Capacitor.getPlatform(), this.runMode, require("@/assets/storage/schema.json"));

    try {
      this.appLocale = (await Device.getLanguageCode())?.value;
    } catch (e) {
      console.error("Error getting device language", e);
    }

    // Get source
    this.source = await schemaKeeper.getSource()
  },

  methods: {

    /**
     * Init app
     */
    async initApp(a2u) {

      // Get device
      const device = a2u.getDevice()

      try {
        // Load native plugins
        if (device.getPlatform() !== 'web') {

          let statusBarPlugin;

          try {
            statusBarPlugin = device.getPlugin("StatusBar");
          } catch (e) {
            console.error("Error getting status bar plugin", e)
          }

          try {
            // Overlay status bar
            await statusBarPlugin?.setOverlaysWebView({overlay: true});
          } catch (e) {
            console.error("Error setting overlay", e)
          }

          try {
            // Define status bar styles
            const statusBarStyles = {
              light: 'LIGHT',
              dark: 'DARK',
            };

            // Apply status bar style
            await statusBarPlugin?.setStyle({
              style: statusBarStyles[a2u?.source?.statusBarMode] || 'DEFAULT',
            });
          } catch (e) {
            console.error("Error setting status bar style", e)
          }

          try {
            // Apply safe areas
            // Get save areas and store to page vars
            const {insets} = await device.getPlugin("SafeArea")?.getSafeAreaInsets() || {};
            for (let [key, value] of Object.entries(insets)) {

              // Skip bottom for android
              if (key === "bottom" && device.getPlatform() === "android") value = 0;

              // Set css var
              document.documentElement.style.setProperty(
                  `--safe-area-${key}`,
                  `${value}px`,
              );
            }
          } catch (e) {
            console.error("Safe area init error", e)
          }

          // Add app state changes
          App.addListener('appStateChange', ({isActive}) => {
            console.log('App state changed. Is active?', isActive)
            if (isActive) a2u.restore(); else a2u.pause();
          });

        }

        // Check for iap config and init iap manager
        if (a2u?.source?.iap) {
          // Init plugin
          await device.getPluginInstance("IAPManager")?.init(a2u.source.iap)
        }

        // Check for cdn config and init cdn manager
        if (a2u?.source?.cdnServer?.endpoint) {
          await device.getPluginInstance('CdnManager')?.init({
            runMode: a2u.runMode,
            platform: a2u.platform,
            cdnServers: a2u.source.cdnServer.endpoint,
          });
        }
      } catch (e) {
        // Error init app
        console.error(e)
      }

      // Post app init
      /*PostInitApp*/

        if (a2u?.source?.shopify) {
          const plugin = device.getPlugin('ShopifyAdapter');

          if (plugin) {
            const instance = new plugin(a2u);

            instance.init(a2u?.source?.shopify);
          }
        }




    },

    /**
     * Destroy app
     * @param a2u
     */
    destroyApp() {

    }
  }
}
</script>
