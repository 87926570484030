import { Capacitor } from '@capacitor/core';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import StackTrace from 'stacktrace-js';
import {BaseErrorHandler} from './BaseErrorHandler';

export class CrashlyticsErrorHandler extends BaseErrorHandler {
  async processError(err) {
    // Check if the platform is native
    if (!Capacitor.isNativePlatform()) {
      console.error(err);
      return;
    }

    try {
      // Prepare payload
      const payload = {
        message: typeof err === 'string' ? err : err?.message || 'Unknown error',
      };

      // Add stack trace
      if (typeof err !== 'string') {
        payload.stacktrace = await StackTrace.fromError(err);
      }

      // Record exception
      await FirebaseCrashlytics.recordException(payload);

      // Send unsent reports
      await FirebaseCrashlytics.sendUnsentReports();
    } catch (e) {
      console.error(e);
    }
  }
}
